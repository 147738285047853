import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppBar, AppBarSection, AppBarSpacer, Drawer, DrawerContent, Menu, MenuItem } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { useAuth } from '../../contexts/AuthContext';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { useTranslation } from 'react-i18next';
import useLoading from '../../hooks/useLoading';
import ENPNG from '../../assets/img/flags/en.png'
import MNPNG from '../../assets/img/flags/mn.png'
import { onForegroundMessage } from '../../firebase';
import { useToasts } from 'react-toast-notifications';

const DrawerRouterContainer = props => {
    const [expanded, setExpanded] = React.useState(true);
    const { logout, auth } = useAuth()
    const { t, i18n } = useTranslation()
    const { showLoading } = useLoading();
    const { addToast } = useToasts()

    const items = [

        {
            text: t('page.control-panel'),
            icon: 'k-i-home',
            selected: true,
            route: '/main',
            roles: {
                branch: true,
                admin: true,
                sysadmin: true,
            }
        },
        {
            separator: true
        },
        {
            text: t('page.inst'),
            icon: 'k-i-set-column-position',
            route: '/inst',
            roles: {
                branch: true,
                admin: true,
                sysadmin: true,
            }
        },
        {
            separator: true
        },
        // {
        //     text: 'page.transaction',
        //     icon: 'k-i-barcode-outline',
        //     route: '/transactions',
        //     roles: {
        //         branch: true,
        //         admin: true,
        //         sysadmin: true,
        //     }
        // },
        // {
        //     separator: true
        // },
        {
            text: t('page.order'),
            icon: 'k-i-barcode-outline',
            route: '/orders',
            roles: {
                branch: true,
                admin: true,
                sysadmin: true,
            }
        },
        {
            separator: true
        },
        {
            text: t('page.user'),
            icon: 'k-i-user',
            route: '/user',
            roles: {
                branch: false,
                admin: true,
                sysadmin: true,
            }
        },
        {
            text: t('page.notification'),
            icon: 'k-i-notification',
            route: '/notification',
            roles: {
                branch: true,
                admin: true,
                sysadmin: true,
            }
        },
    ];

    
    const handleClick = () => {
        setExpanded(!expanded);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLngDINg', lng);
        // history.push(document.location.pathname + '?lng=' + lng)
    }

    const onSelect = e => {
        props.history.push(e.itemTarget.props.route);
    };

    const onMenuSelect = (event) => {
        if (event.item.data) {
            props.history.push(event.item.data.route);
        } else {

        }
    };

    const logoutHandler = async () => {
        showLoading(true);
        await logout()
        showLoading(false);
        window.location.replace('/login')
    }

    const setSelectedItem = pathName => {
        let currentPath = items.find(item => item.route === pathName);
        if (currentPath && currentPath.text) {
            return currentPath.text;
        }
    };

    const render = () => {
        return <div onClick={() => logoutHandler()}>{t('top-right-menu.logout')}</div>
    }

    const renderLang = () => {
        return <div onClick={() => changeLanguage(i18n.language === 'mn' ? 'en' : 'mn')}>
            <img src={i18n.language === 'mn' ? ENPNG : MNPNG} alt="Ding language" width={20} />
            &nbsp; &nbsp;<span style={{ paddingTop: '5px' }}>{t('top-right-menu.changelang')}</span>
        </div>
    }

    const selected = setSelectedItem(props.location.pathname);

    React.useEffect(() => {
        function requestPermission() {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                }
            })
        }
        requestPermission()
    }, [])

    React.useEffect(() => {
        onForegroundMessage()
            .then((payload) => {
                console.log('Received foreground message: ', payload);
                if (payload.data) {
                    if (payload.data.notif_key === 'CREATE_ORDER') {
                        addToast(payload.data.body, { appearance: 'info' })
                    }
                }

            })
            .catch(err => console.log('An error occured while retrieving foreground message. ', err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return auth?.user ? <React.Fragment>
        <AppBar style={{ width: '100wh' }}>
            <AppBarSection>
                <Button icon="menu" onClick={handleClick} />
            </AppBarSection>

            <AppBarSection onClick={logoutHandler}>
                <h2 className="title">Ding</h2>
            </AppBarSection>

            <AppBarSpacer />
            <AppBarSection>
                <BadgeContainer>
                    <span className="k-icon k-i-bell" />
                    <Badge themeColor="info" shape="dot" />
                </BadgeContainer>
            </AppBarSection>
            <AppBarSection>
                <Menu onSelect={onMenuSelect} key="item">
                    <MenuItem
                        text={auth?.user?.fname}
                        key="home"
                        icon='user'
                    >
                        <MenuItem
                            text={t('profile.changepass')}
                            data={{
                                route: "/changepass",
                            }}
                            key="changepass"
                        ></MenuItem>
                        {/* <MenuItem
                            text={t('top-right-menu.google-auth')}
                            data={{
                                route: "/google-auth",
                            }}
                            key="google"
                        ></MenuItem>
                        <MenuItem
                            text={t('top-right-menu.loginhistory')}
                            data={{
                                route: "/login-history",
                            }}
                            key="history"
                        ></MenuItem> */}
                        <MenuItem
                            render={renderLang}
                        ></MenuItem>
                        <MenuItem
                            render={render}
                            icon="logout"
                            key="logout"
                        ></MenuItem>
                    </MenuItem>
                </Menu>
            </AppBarSection>
        </AppBar>

        <Drawer expanded={expanded} mode="push" position="start" mini={true}
            // eslint-disable-next-line array-callback-return
            items={items}
            onSelect={onSelect}>
            <DrawerContent>
                {items.map((item, index) => {
                    return item.selected && <div className="content" id={item.text} key={index}>
                        {props.children}
                    </div>;
                })}
            </DrawerContent>
        </Drawer>
        <style>{`
              .header { padding: 20px; text-align: center; }
              .content { padding: 40px 20px; }
              `}</style>
    </React.Fragment> : <React.Fragment>{props.children}</React.Fragment>;
};

export default withRouter(DrawerRouterContainer);